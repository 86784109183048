<template>
  <div>
    <Navigation/>
    <br>
    <v-row style="justify-content: center;">
      <v-col cols="10" style="text-align: justify;">
        <v-alert border="top" color="info" dark>
          {{ selectedLanguage['support_component_text'] }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row style="justify-content: center;">
      <v-list style="margin: 20px;">
        <v-subheader>{{ selectedLanguage['support_component_text2'] }}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item key="emailcomercial" href="mailto:comercial@wwcalc.com?subject=Comercial">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>comercial@wwcalc.com</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item key="whatsappcomercial" href="https://wa.me/31995364261" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-whatsapp</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>+55 (31) 9 9536 4261</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list style="margin: 20px;">
        <v-subheader>{{ selectedLanguage['support_component_text3'] }}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item key="emailsupport" href="mailto:support@wwcalc.com?subject=Support">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>support@wwcalc.com</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item key="whatsappsupport" href="https://wa.me/31995364261" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-whatsapp</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>+55 (31) 9 8457 9969</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list style="margin: 20px;">
        <v-list-item-group color="primary">
          <v-list-item key="downloadPluguin" v-if="this.ambientEnv != undefined">
            <v-btn v-if="this.ambientEnv == 'prd'" class="ma-2" outlined href="/assets/wwcalc_plugin_prd.zip" download> Download plugin </v-btn>
            <v-btn v-if="this.ambientEnv == 'dev'" class="ma-2" outlined href="/assets/wwcalc_plugin_dev.zip" download> Download plugin </v-btn>
            <v-btn v-if="this.ambientEnv == 'hml'" class="ma-2" outlined href="/assets/wwcalc_plugin_hml.zip" download> Download plugin </v-btn>
            <v-btn v-if="this.ambientEnv == 'local'" class="ma-2" outlined href="/assets/wwcalc_plugin_dev.zip" download> Download plugin </v-btn>
          </v-list-item>
        </v-list-item-group>
      </v-list>

    </v-row>
    <v-row style="justify-content: center;">
      <v-list style="margin-bottom: 60px;">
        <v-subheader>{{ selectedLanguage['support_component_text4'] }}</v-subheader>
        <v-list-item-group color="primary">

          <v-list-item key="railway1" :href="selectedLanguage['support_component']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway2" :href="selectedLanguage['support_component_video_2']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_2'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway3" :href="selectedLanguage['support_component_video_3']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_3'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway4" :href="selectedLanguage['support_component_video_4']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_4'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway5" :href="selectedLanguage['support_component_video_5']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_5'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway6" :href="selectedLanguage['support_component_video_6']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_6'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway7" :href="selectedLanguage['support_component_video_7']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_7'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway8" :href="selectedLanguage['support_component_video_8']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_8'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="railway9" :href="selectedLanguage['support_component_video_9']" target="_blank">
            <v-list-item-icon>
              <v-icon :color="primary">mdi-play-network-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ selectedLanguage['support_component_text5_video_9'] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-row>
  </div>
</template>

<script>
import Navigation from './Navigation'
import { mapGetters } from 'vuex'
import { portuguese } from '../languages/pt-br';
import { english } from '../languages/en';

export default {
  name: 'Support',
  components: { Navigation },
  mounted: async function(){
    this.ambientEnv = process.env.VUE_APP_VAR_SELECT
  },
  data() {
    return {
      primary: 'primary',
      ambientEnv: undefined,
    }
  },
  computed: {
    ...mapGetters(['profile']),
    selectedLanguage() {
      return this.profile.language === 'en' ? english : portuguese;
    },
  },
}
</script>
